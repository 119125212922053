import React from 'react'
import { Link } from 'react-scroll'

function BurgerDrawer(props) {
  const { showBurgerDrawer } = props

  return(
    <nav className={showBurgerDrawer ? "burger-drawer open-burger" : "burger-drawer close-burger"}>
      <ul>
        <Link
          className="burger-link"
          activeClass="active"
          to="banner-container"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}
          >
          <a href="#about">
            About
          </a>
        </Link>
        <Link
          className="burger-link"
          activeClass="active"
          to="projects-container"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}
        >
          <a href="#projects">
            Projects
          </a>
        </Link>
        <Link
          className="burger-link"
          activeClass="active"
          to="contact-container"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}
        >
          <a href="#contact">
            Contact
          </a>
        </Link>
      </ul>
    </nav>
  )
}

export default BurgerDrawer
