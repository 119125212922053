import React from 'react'
import Burger from './burger.js'
import { Spring } from 'react-spring/renderprops'
import { Link } from 'react-scroll'

function Nav2(props){
  const { burgerClickHandler } = props
    return(

      <Spring
        from={{ opacity: 0, marginTop: -100 }}
        to={{ opacity: 1, marginTop: 0 }}
      >
        { props => (
          <nav style={props} className="main-nav">
            <div>
              <Burger clickBurger={burgerClickHandler} />
            </div>
            <h2 className="nav-name">
              <Link
                activeClass="active"
                to="banner-container"
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                Eric Bondoc
              </Link>
            </h2>
          </nav>
        )}
      </Spring>

    )
}

export default Nav2
