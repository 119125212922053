import React from 'react'
import profile from '../images/profile.JPG'

class Banner extends React.Component {

  render () {
    return(
      <div className="banner-container">
        <div className="profile-pic-container">
          <img className="profile-pic" src={profile} alt="Eric Bondoc profile"/>
        </div>
        <h3 className="banner-message">Hello! I'm a Software Developer from Southern California. I'm extremely passionate about making an impact on local communities by creating intuitive user experiences. Let's work together to make something special!</h3>
      </div>
    )
  }
}

export default Banner
