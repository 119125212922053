import React from 'react';
import { Spring } from 'react-spring/renderprops'
import Nav2 from './nav2'
import BurgerDrawer from './burgerdrawer'
import Banner from './banner'
import Skills from './skills'
import Projects from './projects'
import Contact from './contact'
import Footer from './footer'


function Homepage(props){
    const { burgerClickHandler, backgroundClickHandler, showBurgerDrawer } = props

    return (
      <React.Fragment>
        <div style={{height: '100%'}}>
            <Nav2
              showBurgerDrawer={showBurgerDrawer}
              burgerClickHandler={burgerClickHandler}
            />

              <BurgerDrawer
                showBurgerDrawer={showBurgerDrawer}
                burgerClickHandler={burgerClickHandler}
                backgroundClickHandler={backgroundClickHandler}
              />

          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ delay: 1000, duration: 1000 }}
          >
            { props => (
              <div style={props}>
                <Banner />
                <Skills />
                <Projects />
                <Contact />
              </div>
            )}
          </Spring>
        </div>
        <Footer />
      </React.Fragment>
    )
  }


export default Homepage;
