import React from 'react'

class Projects extends React.Component {
  state = {
    projectHover: false,
    showStyle: ''
  }

  toggleHover(){
    this.setState(
      (prevState) => {
        return {projectHover: !prevState.projectHover}
    })
  }

  render(){

    return(
      <div className="projects-container">
        <h1>Projects</h1>

        <div className="project-cards-container">

        <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://myballot.netlify.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/myBallot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>myBallot</h4>
                <h5>ReactJS, Voter API</h5>
              </div>
            </div>
          </div>

          <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://whats-the-weather-eb.netlify.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/Whats-The-Weather"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>What's The Weather</h4>
                <h5>ReactJS, Weather API</h5>
              </div>
            </div>
          </div>

          <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://myapptrack.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/AppTrack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>AppTrack</h4>
                <h5>MERN stack</h5>
              </div>
            </div>
          </div>

          <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://eb46.github.io/GA-Project-1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/eb46.github.io/tree/master/GA-Project-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>The Office Trivia!</h4>
                <h5>jQuery</h5>
              </div>
            </div>
          </div>

          <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://limitless-beach-09379.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/homeFit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>homeFit</h4>
                <h5>JavaScript, Express, MongoDB</h5>
              </div>
            </div>
          </div>

          <div className="project">
            <div
              className="project-tag"
            >
              <div className="view-project-container">
                <a
                  className="view-project-text"
                  href="https://techi-app.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Link
                </a>
                <a
                  className="view-project-text view-project-code"
                  href="https://github.com/eb46/Techi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
              </div>
              <div className="project-image">
                <h4>Techi</h4>
                <h5>ReactJS, PHP, PostgresSQL</h5>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Projects
