import React from 'react';
import Homepage from './components/homepage'


class App extends React.Component {

  state = {
    showBurgerDrawer: false,
  }

  burgerClickHandler = () => {
    this.setState(
      (prevState) => {
        return {showBurgerDrawer: !prevState.showBurgerDrawer}
    })
    console.log('button clicked');
  }

  backgroundClickHandler = () => {
    this.setState({
      showBurgerDrawer: false
    })
  }

  render () {
    return (
      <React.Fragment>
        <Homepage
          showBurgerDrawer={this.state.showBurgerDrawer}
          burgerClickHandler={this.burgerClickHandler}
          backgroundClickHandler={this.backgroundClickHandler}
        />
      </React.Fragment>
    )
  }

}

export default App;
