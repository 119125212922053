import React from "react";
import SkillsIcon from "./skills_icons";
import angular from "../images/icons/angular.png";
import css from "../images/icons/css.png";
import html from "../images/icons/html.png";
import javascript from "../images/icons/javascript.png";
import jQuery from "../images/icons/jQuery-color.png";
import mongodb from "../images/icons/mongodb.png";
import node from "../images/icons/node.png";
import php from "../images/icons/php-color.png";
import postgres from "../images/icons/postgres.png";
import react from "../images/icons/react.png";
import sass from "../images/icons/sass.png";
import ruby from "../images/icons/ruby.png";
import reactNative from "../images/icons/react-native.png";
import typeScript from "../images/icons/typescript.png";

const icons = [
  {
    image: react,
    title: "React",
  },
  {
    image: reactNative,
    title: "React Native",
  },
  {
    image: angular,
    title: "Angular",
  },
  {
    image: css,
    title: "CSS",
  },
  {
    image: sass,
    title: "Sass",
  },
  {
    image: html,
    title: "HTML5",
  },
  {
    image: javascript,
    title: "JavaScript",
  },
  {
    image: typeScript,
    title: "TypeScript",
  },
  {
    image: jQuery,
    title: "jQuery",
  },
  {
    image: node,
    title: "Node",
  },
  {
    image: ruby,
    title: "Ruby",
  },
  {
    image: php,
    title: "PHP",
  },
  {
    image: mongodb,
    title: "MongoDB",
  },
  {
    image: postgres,
    title: "PostgreSQL",
  },
];

class Skills extends React.Component {
  render() {
    return (
      <div className="skills-overlay">
        <div className="skills-container">
          <h1>Skills</h1>
          <div className="tech-stack">
            {icons.map((icon, index) => (
              <SkillsIcon key={index} icon={icon} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
