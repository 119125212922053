import React from 'react'

function Footer(){
  return(
    <footer>
        <h5>Copyright @ Eric Bondoc</h5>
    </footer>
  )
}

export default Footer
