import React from 'react'
import github from '../images/icons/github.png'
import linkedin from '../images/icons/linkedin.png'

function Contact(){
  return(
    <div className="contact-container">
      <div>
        <a
          href="https://github.com/eb46/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-icon"
            src={github}
            alt="GitHub icon"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/eric-bondoc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-icon linkedin" src={linkedin}
            alt="LinkedIn icon"
          />
        </a>
      </div>
      <a
        className="email"
        href="mailto: ericjbondoc@gmail.com">
          ericjbondoc@gmail.com
      </a>
    </div>
  )
}

export default Contact
