import React, { useState } from 'react'

function Burger(props) {

  const [status, setStatus] = useState('closed')
  return(
      <button onClick={() => {
        setStatus(status === 'open' ? 'close' : 'open')
        props.clickBurger()
        }}
        className="toggle-burger">
          <div className={status} />
          <div className={status} />
          <div className={status} />
      </button>
    )
  }

export default Burger
